import revive_payload_client_4sVQNw7RlN from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/boligkartoteket-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/var/www/boligkartoteket-frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import strapi_plugin_35gTOGMBRa from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import composition_sLxaNGmlSL from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import backed_errors_in_console_iYoC5QHCgg from "/var/www/boligkartoteket-frontend/plugins/backed-errors-in-console.ts";
import phosphorvue_NFtQ0rHri6 from "/var/www/boligkartoteket-frontend/plugins/phosphorvue.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  strapi_plugin_35gTOGMBRa,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  backed_errors_in_console_iYoC5QHCgg,
  phosphorvue_NFtQ0rHri6
]