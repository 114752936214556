import { default as about8ntv4eJLPHMeta } from "/var/www/boligkartoteket-frontend/pages/about.vue?macro=true";
import { default as accountazM9eYfISqMeta } from "/var/www/boligkartoteket-frontend/pages/administration/account.vue?macro=true";
import { default as change_45passwordbAw0VYyPBxMeta } from "/var/www/boligkartoteket-frontend/pages/administration/change-password.vue?macro=true";
import { default as dashboardj4PIZwJCUBMeta } from "/var/www/boligkartoteket-frontend/pages/administration/dashboard.vue?macro=true";
import { default as invoicesPSIxXy5nASMeta } from "/var/www/boligkartoteket-frontend/pages/administration/invoices.vue?macro=true";
import { default as payment_45methodvHSe52o7t5Meta } from "/var/www/boligkartoteket-frontend/pages/administration/payment-method.vue?macro=true";
import { default as indexrzbUKVcaIRMeta } from "/var/www/boligkartoteket-frontend/pages/administration/residences/[id]/index.vue?macro=true";
import { default as contactufBwffxWE2Meta } from "/var/www/boligkartoteket-frontend/pages/contact.vue?macro=true";
import { default as cookieshG7DVQkSLtMeta } from "/var/www/boligkartoteket-frontend/pages/cookies.vue?macro=true";
import { default as faqkDjv9A6Md8Meta } from "/var/www/boligkartoteket-frontend/pages/faq.vue?macro=true";
import { default as health_45checkh9w0n9T3xTMeta } from "/var/www/boligkartoteket-frontend/pages/health-check.vue?macro=true";
import { default as help0vY2rgz4XfMeta } from "/var/www/boligkartoteket-frontend/pages/help.vue?macro=true";
import { default as indexybu32vO3jZMeta } from "/var/www/boligkartoteket-frontend/pages/index.vue?macro=true";
import { default as loginrJdyvHmtJQMeta } from "/var/www/boligkartoteket-frontend/pages/login.vue?macro=true";
import { default as privacySDbFnScUXSMeta } from "/var/www/boligkartoteket-frontend/pages/privacy.vue?macro=true";
import { default as reset_45passwordpUs7q8xuTnMeta } from "/var/www/boligkartoteket-frontend/pages/reset-password.vue?macro=true";
import { default as indexIqTfENR1c2Meta } from "/var/www/boligkartoteket-frontend/pages/residences/[id]/index.vue?macro=true";
import { default as searchtj1Z84TvuIMeta } from "/var/www/boligkartoteket-frontend/pages/search.vue?macro=true";
import { default as signupGs2NswcxH6Meta } from "/var/www/boligkartoteket-frontend/pages/signup.vue?macro=true";
export default [
  {
    name: about8ntv4eJLPHMeta?.name ?? "about",
    path: about8ntv4eJLPHMeta?.path ?? "/about",
    meta: about8ntv4eJLPHMeta || {},
    alias: about8ntv4eJLPHMeta?.alias || [],
    redirect: about8ntv4eJLPHMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accountazM9eYfISqMeta?.name ?? "administration-account",
    path: accountazM9eYfISqMeta?.path ?? "/administration/account",
    meta: accountazM9eYfISqMeta || {},
    alias: accountazM9eYfISqMeta?.alias || [],
    redirect: accountazM9eYfISqMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/account.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordbAw0VYyPBxMeta?.name ?? "administration-change-password",
    path: change_45passwordbAw0VYyPBxMeta?.path ?? "/administration/change-password",
    meta: change_45passwordbAw0VYyPBxMeta || {},
    alias: change_45passwordbAw0VYyPBxMeta?.alias || [],
    redirect: change_45passwordbAw0VYyPBxMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/change-password.vue").then(m => m.default || m)
  },
  {
    name: dashboardj4PIZwJCUBMeta?.name ?? "administration-dashboard",
    path: dashboardj4PIZwJCUBMeta?.path ?? "/administration/dashboard",
    meta: dashboardj4PIZwJCUBMeta || {},
    alias: dashboardj4PIZwJCUBMeta?.alias || [],
    redirect: dashboardj4PIZwJCUBMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/dashboard.vue").then(m => m.default || m)
  },
  {
    name: invoicesPSIxXy5nASMeta?.name ?? "administration-invoices",
    path: invoicesPSIxXy5nASMeta?.path ?? "/administration/invoices",
    meta: invoicesPSIxXy5nASMeta || {},
    alias: invoicesPSIxXy5nASMeta?.alias || [],
    redirect: invoicesPSIxXy5nASMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodvHSe52o7t5Meta?.name ?? "administration-payment-method",
    path: payment_45methodvHSe52o7t5Meta?.path ?? "/administration/payment-method",
    meta: payment_45methodvHSe52o7t5Meta || {},
    alias: payment_45methodvHSe52o7t5Meta?.alias || [],
    redirect: payment_45methodvHSe52o7t5Meta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/payment-method.vue").then(m => m.default || m)
  },
  {
    name: indexrzbUKVcaIRMeta?.name ?? "administration-residences-id",
    path: indexrzbUKVcaIRMeta?.path ?? "/administration/residences/:id()",
    meta: indexrzbUKVcaIRMeta || {},
    alias: indexrzbUKVcaIRMeta?.alias || [],
    redirect: indexrzbUKVcaIRMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/residences/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: contactufBwffxWE2Meta?.name ?? "contact",
    path: contactufBwffxWE2Meta?.path ?? "/contact",
    meta: contactufBwffxWE2Meta || {},
    alias: contactufBwffxWE2Meta?.alias || [],
    redirect: contactufBwffxWE2Meta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: cookieshG7DVQkSLtMeta?.name ?? "cookies",
    path: cookieshG7DVQkSLtMeta?.path ?? "/cookies",
    meta: cookieshG7DVQkSLtMeta || {},
    alias: cookieshG7DVQkSLtMeta?.alias || [],
    redirect: cookieshG7DVQkSLtMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: faqkDjv9A6Md8Meta?.name ?? "faq",
    path: faqkDjv9A6Md8Meta?.path ?? "/faq",
    meta: faqkDjv9A6Md8Meta || {},
    alias: faqkDjv9A6Md8Meta?.alias || [],
    redirect: faqkDjv9A6Md8Meta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: health_45checkh9w0n9T3xTMeta?.name ?? "health-check",
    path: health_45checkh9w0n9T3xTMeta?.path ?? "/health-check",
    meta: health_45checkh9w0n9T3xTMeta || {},
    alias: health_45checkh9w0n9T3xTMeta?.alias || [],
    redirect: health_45checkh9w0n9T3xTMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: help0vY2rgz4XfMeta?.name ?? "help",
    path: help0vY2rgz4XfMeta?.path ?? "/help",
    meta: help0vY2rgz4XfMeta || {},
    alias: help0vY2rgz4XfMeta?.alias || [],
    redirect: help0vY2rgz4XfMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexybu32vO3jZMeta?.name ?? "index",
    path: indexybu32vO3jZMeta?.path ?? "/",
    meta: indexybu32vO3jZMeta || {},
    alias: indexybu32vO3jZMeta?.alias || [],
    redirect: indexybu32vO3jZMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginrJdyvHmtJQMeta?.name ?? "login",
    path: loginrJdyvHmtJQMeta?.path ?? "/login",
    meta: loginrJdyvHmtJQMeta || {},
    alias: loginrJdyvHmtJQMeta?.alias || [],
    redirect: loginrJdyvHmtJQMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacySDbFnScUXSMeta?.name ?? "privacy",
    path: privacySDbFnScUXSMeta?.path ?? "/privacy",
    meta: privacySDbFnScUXSMeta || {},
    alias: privacySDbFnScUXSMeta?.alias || [],
    redirect: privacySDbFnScUXSMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordpUs7q8xuTnMeta?.name ?? "reset-password",
    path: reset_45passwordpUs7q8xuTnMeta?.path ?? "/reset-password",
    meta: reset_45passwordpUs7q8xuTnMeta || {},
    alias: reset_45passwordpUs7q8xuTnMeta?.alias || [],
    redirect: reset_45passwordpUs7q8xuTnMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexIqTfENR1c2Meta?.name ?? "residences-id",
    path: indexIqTfENR1c2Meta?.path ?? "/residences/:id()",
    meta: indexIqTfENR1c2Meta || {},
    alias: indexIqTfENR1c2Meta?.alias || [],
    redirect: indexIqTfENR1c2Meta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/residences/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: searchtj1Z84TvuIMeta?.name ?? "search",
    path: searchtj1Z84TvuIMeta?.path ?? "/search",
    meta: searchtj1Z84TvuIMeta || {},
    alias: searchtj1Z84TvuIMeta?.alias || [],
    redirect: searchtj1Z84TvuIMeta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: signupGs2NswcxH6Meta?.name ?? "signup",
    path: signupGs2NswcxH6Meta?.path ?? "/signup",
    meta: signupGs2NswcxH6Meta || {},
    alias: signupGs2NswcxH6Meta?.alias || [],
    redirect: signupGs2NswcxH6Meta?.redirect || undefined,
    component: () => import("/var/www/boligkartoteket-frontend/pages/signup.vue").then(m => m.default || m)
  }
]