import {
    PhHouse,
    PhMagnifyingGlass,
    PhCaretUp,
    PhSignOut,
    PhClipboard,
    PhMetaLogo,
    PhInstagramLogo,
    PhTwitterLogo,
    PhSignIn,
    PhUserPlus
} from '@phosphor-icons/vue'

export default defineNuxtPlugin((nuxtApp) => {    
    nuxtApp.vueApp.component('PhHouse', PhHouse)
    nuxtApp.vueApp.component('PhMagnifyingGlass', PhMagnifyingGlass)
    nuxtApp.vueApp.component('PhCaretUp', PhCaretUp)
    nuxtApp.vueApp.component('PhSignOut', PhSignOut)
    nuxtApp.vueApp.component('PhClipboard', PhClipboard)
    nuxtApp.vueApp.component('PhMetaLogo', PhMetaLogo)
    nuxtApp.vueApp.component('PhInstagramLogo', PhInstagramLogo)
    nuxtApp.vueApp.component('PhTwitterLogo', PhTwitterLogo)
    nuxtApp.vueApp.component('PhSignIn', PhSignIn)
    nuxtApp.vueApp.component('PhUserPlus', PhUserPlus)
})